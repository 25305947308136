import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  deleteRequest,
  getRequest,
  postRequest,
  putRequest,
} from "../../utils/fetchMethods";
import { notify } from "../../utils/notify";

// ----------- redux thunk ----------
export const getSubjectsList: any = createAsyncThunk(
  "getSubjectsList",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, "/api/subject/list");

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getSubjectDetails: any = createAsyncThunk(
  "getSubjectDetails",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/subject/show/${params}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      throw new Error(error);
    }
  }
);

export const getUnitsformSubject: any = createAsyncThunk(
  "getUnitsformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest(
        params.data,
        `/api/subject/units/${params.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getGradesformSubject: any = createAsyncThunk(
  "getGradesformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest(
        params.data,
        `/api/subject/grades/${params.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result?.grade;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getTeachersformSubject: any = createAsyncThunk(
  "getTeachersformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest(
        params.data,
        `/api/subject/teachers/${params.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result?.teachers;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getStudentsformSubject: any = createAsyncThunk(
  "getStudentsformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest(
        params.data,
        `/api/subject/students/${params.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result?.students;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addSubject: any = createAsyncThunk("addSubject", async (body) => {
  try {
    const result = await postRequest(body, "/api/subject/store");

    if (!result.status) {
      throw new Error(result.msg);
    }

    notify("Subject created successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});

export const editSubject: any = createAsyncThunk(
  "editSubject",
  async (body: any) => {
    try {
      const result = await postRequest(
        body.data,
        `/api/subject/update/${body.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Subject created successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteSubject: any = createAsyncThunk(
  "deleteSubject",
  async (body: any) => {
    try {
      const result = await postRequest(
        body?.data,
        `/api/subject/delete/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Subject deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const assignSubjectToGrade: any = createAsyncThunk(
  "assignSubjectToGrade",
  async (body: any) => {
    try {
      const result = await postRequest(
        { ...body.data, _method: "PUT" },
        `/api/subject/assignGrade/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Grade assigned successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const setSubjectStatus: any = createAsyncThunk(
  "setSubjectStatus",
  async (body: any) => {
    try {
      const result = await postRequest(
        { _method: "PUT", ...body.data },
        `/api/subject/status/${body.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addUnitToSubject: any = createAsyncThunk(
  "addUnitToSubject",
  async (body) => {
    try {
      const result = await postRequest(body, `/api/units/store`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Unit created successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const editUnitToSubject: any = createAsyncThunk(
  "editUnitToSubject",
  async (body: any) => {
    try {
      const result = await postRequest(
        { ...body?.data, _method: "PUT" },
        `/api/units/update/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Unit updated successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const changeUnitType: any = createAsyncThunk(
  "changeUnitType",
  async (body: any) => {
    try {
      const result = await postRequest(
        { ...body?.data, _method: "PUT" },
        `/api/units/status_type/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteUnit: any = createAsyncThunk(
  "deleteUnit",
  async (body: any) => {
    try {
      const result = await deleteRequest({}, `/api/units/delete/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Unit deleted successfully", "success");
      return result;
    } catch (error: any) {
      if (error?.message?.slice(-3) == "400") {
        notify(
          "The unit cannot be deleted because it contains lessons",
          "error"
        );
      } else {
        notify(error?.message, "error");
      }
      throw new Error(error);
    }
  }
);

export const getQuizzesformSubject: any = createAsyncThunk(
  "getQuizzesformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, `/api/quizes/list`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result?.quizes;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const QuizDetailsformSubject: any = createAsyncThunk(
  "QuizDetailsformSubject",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/quizes/show/${params}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addQuizToSubject: any = createAsyncThunk(
  "addQuizToSubject",
  async (body) => {
    try {
      const result = await postRequest(body, `/api/quizes/store`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Quiz created successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteQuiz: any = createAsyncThunk(
  "deleteQuiz",
  async (body: any) => {
    try {
      const result = await deleteRequest({}, `/api/quizes/delete/${body}`);

      if (!result.status) {
        notify(result.msg, "error");
        return;
      }

      notify("Quiz deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const getQuizDetails: any = createAsyncThunk(
  "getQuizDetails",
  async (body: any) => {
    try {
      const result = await getRequest({}, `/api/quizes/show/${body}`);

      if (!result.status) {
        notify(result.msg, "error");
        return;
      }

      // notify("Quiz deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const editQuiz = createAsyncThunk(
  "editQuiz",
  // @ts-ignore
  async ({ body, params }) => {
    try {
      const result = await postRequest(body, `/api/quizes/update/${params}`);

      if (!result.status) {
        throw new Error(result.msg);
      }
      notify("Quiz updated successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const getLessonDetails: any = createAsyncThunk(
  "getLesson",
  async (body: any) => {
    try {
      const result = await getRequest({}, `/api/lessons/show/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteLesson: any = createAsyncThunk(
  "deleteLesson",
  async (body: any) => {
    try {
      const result = await deleteRequest({}, `/api/lessons/delete/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Lesson deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const lessonToUnitList: any = createAsyncThunk(
  "lessonToUnitlist",
  async (body) => {
    try {
      const result = await getRequest({}, `/api/units/show/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addLessonToUnit: any = createAsyncThunk(
  "addLessonToUnit",
  async (body) => {
    try {
      const result = await postRequest(body, `/api/lessons/store`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Lesson created successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const editLessonToUnit: any = createAsyncThunk(
  "editLessonToUnit",
  async (body: any) => {
    try {
      const result = await postRequest(
        { ...body.data, _method: "PUT" },
        `/api/lessons/update/${body.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Lesson updated successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addContentToLesson: any = createAsyncThunk(
  "addContentToLesson",
  async (body: any) => {
    // console.log('body',body);
    
    try {
      const result = await postRequest(body, `/api/contents/store`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Content added successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const editContentInLesson: any = createAsyncThunk(
  "editContentInLesson",
  async (body: any) => {
    try {
      const result = await postRequest(
        body.data,
        `/api/contents/update/${body.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Content edited successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addExternalLinkToContent: any = createAsyncThunk(
  "addExternalLinkToContent",
  async (body: any) => {
    try {
      const result = await postRequest(
        { ...body, type: "external_link" },
        `/api/resources/store`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("External link added successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const viewContent: any = createAsyncThunk(
  "viewContent",
  async (body: any) => {
    try {
      const result = await getRequest({}, `/api/contents/show/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const deleteContent: any = createAsyncThunk(
  "deleteContent",
  async (body: any) => {
    try {
      const result = await deleteRequest({}, `/api/contents/delete/${body}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Content deleted successfully");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const getGamesDetalis: any = createAsyncThunk(
  "getGamesDetalis",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, `/api/games/list`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const getGamesShow: any = createAsyncThunk(
  "getGamesDetalis",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/games/show/${params.id}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

export const addGame: any = createAsyncThunk("addGame", async (body) => {
  try {
    const result = await postRequest(body, "/api/games/store");

    if (!result.status) {
      throw new Error(result.msg);
    }

    notify("Game created successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});

export const editGame: any = createAsyncThunk("editGame", async (body: any) => {
  try {
    const result = await postRequest(body, `/api/games/update/${body?.id}`);

    if (!result.status) {
      throw new Error(result.msg);
    }

    notify("Game updated successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});
export const deleteGame: any = createAsyncThunk(
  "deleteGame",
  async (body: any) => {
    try {
      const result = await deleteRequest(
        { ...body?.data, _method: "PUT" },
        `/api/games/delete/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Game deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const getSheetsDetalis: any = createAsyncThunk(
  "getSheetsDetalis",
  async (params: any = {}) => {
    try {
      const result = await getRequest(params, `/api/worksheets/list`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const addSheet: any = createAsyncThunk("addSheet", async (body) => {
  try {
    const result = await postRequest(body, "/api/worksheets/store");

    if (!result.status) {
      throw new Error(result.msg);
    }

    notify("Worksheet created successfully", "success");
    return result;
  } catch (error: any) {
    notify(error.message, "error");
    throw new Error(error);
  }
});
export const editSheet: any = createAsyncThunk(
  "editSheet",
  async (body: any) => {
    try {
      const result = await postRequest(
        body,
        `/api/worksheets/update/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Worksheet updated successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const getSheetShow: any = createAsyncThunk(
  "getSheetShow",
  async (params: any = {}) => {
    try {
      const result = await getRequest({}, `/api/worksheets/show/${params.id}`);

      if (!result.status) {
        throw new Error(result.msg);
      }

      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);
export const deleteSheet: any = createAsyncThunk(
  "deleteSheet",
  async (body: any) => {
    try {
      const result = await deleteRequest(
        { ...body?.data, _method: "PUT" },
        `/api/worksheets/delete/${body?.id}`
      );

      if (!result.status) {
        throw new Error(result.msg);
      }

      notify("Worksheet deleted successfully", "success");
      return result;
    } catch (error: any) {
      notify(error.message, "error");
      throw new Error(error);
    }
  }
);

// ------------ initial state -----------
export interface subjectsState {
  subjectsList: any;
  subjectOverview: any;
  units: any;
  grades: any;
  teachers: any;
  students: any;
  games: any;
  quizzes: any;
  sheets: any;
}

const initialState: subjectsState = {
  subjectsList: {},
  subjectOverview: {},
  units: {},
  grades: {},
  teachers: {},
  students: {},
  games: {},
  quizzes: {},
  sheets: {},
};

// ------------ reducers ---------------
export const subjectsSlice = createSlice({
  name: "subjects",
  initialState,
  reducers: {},
  extraReducers: {
    [getSubjectsList.fulfilled]: (state: any, { payload }) => {
      state.subjectsList = payload;
    },
    [getSubjectDetails.fulfilled]: (state: any, { payload }) => {
      state.subjectOverview = payload;
    },
    [getUnitsformSubject.fulfilled]: (state: any, { payload }) => {
      state.units = payload;
    },
    [getGradesformSubject.fulfilled]: (state: any, { payload }) => {
      state.grades = payload;
    },
    [getTeachersformSubject.fulfilled]: (state: any, { payload }) => {
      state.teachers = payload;
    },
    [getStudentsformSubject.fulfilled]: (state: any, { payload }) => {
      state.students = payload;
    },
    [getGamesDetalis.fulfilled]: (state: any, { payload }) => {
      state.games = payload;
    },
    [getQuizzesformSubject.fulfilled]: (state: any, { payload }) => {
      state.quizzes = payload;
    },
    [getSheetsDetalis.fulfilled]: (state: any, { payload }) => {
      state.sheets = payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {} = subjectsSlice.actions;

export default subjectsSlice.reducer;
