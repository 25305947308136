import { Box, Divider, Typography } from "@mui/material";
import MatchingFlow from "../shared/MatchingFlow";
import { imageFormats } from "@/constants/constants";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
//@ts-ignore
import bird from "@/assets/bird.svg";
import AudioIcon from "./AudioIcon";

type Props = {
  type: "MCQ" | "TF" | "SHN" | "Matching";
  question: any;
  score: string;
  answer: string[];
  corAnswer?: any;
  code?: any;
};

const QuestionPreview = ({
  type,
  question,
  score,
  answer,
  corAnswer,
  code,
}: Props) => {
  console.log(question.split(",")[0]?.includes(".mp3"));

  // console.log(answer
  //               ?.filter((item: any) => {
  //                 return item;
  //               }));

  return (
    <Box sx={{ p: 5 }}>
      {/* ---------------- MCQ ------------------ */}
      {type === "MCQ" && (
        <Box
          sx={{
            py: 10,
            mx: "auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
            },
          }}
        >
          <p className="text-[#f7ad66] py-2 flex flex-row items-center gap-1">
            <span className="bg-[#f7ad66] text-white w-7 h-7 justify-center items-center rounded-full inline-flex">
              <MenuBookIcon className="" fontSize="small" />
            </span>{" "}
            <p className="text-bold text-lg">Reading</p>
          </p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "16px",
              fontWeight: "600",
              fontSize: "18px",
              marginBottom: "16px",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "16px",
                fontWeight: "600",
                fontSize: "18px",
                pb: 5,
              }}
            >
              {question?.split(",")[0] &&
              question.split(",")[0]?.includes(".mp3") ? (
                // <audio src={question.split(",")[0]} controls/>
                <AudioIcon src={question.split(",")[0]} />
              ) : question.split(",")[0]?.includes(".jpg") ||
                question.split(",")[0]?.includes(".png") ? (
                <img className="w-96" src={question.split(",")[0]} alt="" />
              ) : question.split(",")[0]?.includes(".mp4") ||
                question.split(",")[0]?.includes(".mkv") ||
                question.split(",")[0]?.includes(".avi") ||
                question.split(",")[0]?.includes(".ogg") ? (
                <video width="320" height="240" controls>
                  <source src={question.split(",")[0]} type="video/mp4" />
                  <source src={question.split(",")[0]} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="inline-block py-2">{question.split(",")[0]}</p>
              )}
              {question?.split(",")[1] &&
              question.split(",")[1]?.includes(".mp3") ? (
                // <audio src={question.split(",")[1]}  controls/>
                <AudioIcon src={question.split(",")[1]} />
              ) : question.split(",")[1]?.includes(".jpg") ||
                question.split(",")[1]?.includes(".png") ? (
                <img className="w-96" src={question.split(",")[1]} alt="" />
              ) : question.split(",")[1]?.includes(".mp4") ||
                question.split(",")[1]?.includes(".mkv") ||
                question.split(",")[1]?.includes(".avi") ||
                question.split(",")[1]?.includes(".ogg") ? (
                <video width="321" height="241" controls>
                  <source src={question.split(",")[1]} type="video/mp4" />
                  <source src={question.split(",")[1]} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="inline-block">{question.split(",")[1]}</p>
              )}
            </Typography>
          </Box>
          {question?.split(",")[2] &&
          question.split(",")[2]?.includes(".mp3") ? (
            // <audio src={question.split(",")[2]} controls/>
            <AudioIcon src={question.split(",")[2]} />
          ) : question.split(",")[2]?.includes(".jpg") ||
            question.split(",")[2]?.includes(".png") ? (
            <img
              src={question.split(",")[2]}
              alt=""
              className="w-64 my-10 mr-auto shadow-lg rounded-lg"
            />
          ) : question.split(",")[2]?.includes(".mp4") ||
            question.split(",")[2]?.includes(".mkv") ||
            question.split(",")[2]?.includes(".avi") ||
            question.split(",")[2]?.includes(".ogg") ? (
            <video width="322" height="242" controls>
              <source src={question.split(",")[2]} type="video/mp4" />
              <source src={question.split(",")[2]} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p className="py-2">{question.split(",")[2]}</p>
          )}
          {code && (
            <p className="text-md text-gray font-bold">
              Standard Code : <span className="text-md">({code})</span>
            </p>
          )}
          <p className="text-md text-gray font-bold">
            Your answer : <span className="text-md">({score} points)</span>
          </p>
          <div className="flex flex-row items-center justify-evenly gap-10">
            {answer
              ?.filter((item: any) => {
                return item;
              })
              .map((item: any, index: any) => {
                const corChoice = corAnswer?.split(",");
                const img = item?.split(",")?.find((x: any) => {
                  // console.log("items",item);
                  // console.log("corchoice",corChoice.includes(`${index + 1}`));

                  return imageFormats?.includes(x.slice(-4));
                });
                const option = item?.split(",")?.filter((x: any) => {
                  return x !== img;
                });

                return (
                  <div
                    key={item}
                    className={`flex-1 my-3 p-4 flex flex-row font-bold justify-stretch items-center gap-2 rounded-xl border-b-8 ${
                      corChoice.includes(`${index + 1}`)
                        ? "border border-green bg-lightGreen"
                        : corChoice.includes(`${item}`)
                        ? "border border-green bg-lightGreen"
                        : "border border-easyGray bg-white"
                    }`}
                  >
                    <div>
                      {option?.join("").includes(".mp3") ? (
                        <AudioIcon src={option?.join("")} />
                      ) : //  <audio src={option?.join("")} controls/>
                      option?.join("").includes(".png") ||
                        option?.join("").includes(".jpg") ? (
                        <img src={option?.join("")} alt="" />
                      ) : (
                        option?.join("")
                      )}
                    </div>
                    <div className="mr-auto w-52 my-3">
                      <img src={img} alt="" />
                    </div>
                  </div>
                );
              })}
          </div>
        </Box>
      )}
      {/* ---------------- True or False ------------------ */}
      {type === "TF" && (
        <Box
          sx={{
            mx: "auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
            },
          }}
        >
          <p className="text-[#f7ad66] py-2 flex flex-row items-center gap-1">
            <span className="bg-[#f7ad66] text-white w-7 h-7 justify-center items-center rounded-full inline-flex">
              <MenuBookIcon className="" fontSize="small" />
            </span>{" "}
            <p className="text-bold text-lg">Reading</p>
          </p>

          <Box>
            <Typography
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
                gap: "16px",
                fontWeight: "600",
                fontSize: "18px",
              }}
              variant="h5"
            >
              {question?.split(",")[0] &&
              question.split(",")[0]?.includes(".mp3") ? (
                // <audio src={question.split(",")[0]} controls/>
                <AudioIcon src={question.split(",")[0]} />
              ) : question.split(",")[0]?.includes(".jpg") ||
                question.split(",")[0]?.includes(".png") ? (
                <img
                  className="w-72 my-10 mx-auto shadow-lg rounded-lg"
                  src={question.split(",")[0]}
                  alt=""
                />
              ) : question.split(",")[0]?.includes(".mp4") ||
                question.split(",")[0]?.includes(".mkv") ||
                question.split(",")[0]?.includes(".avi") ||
                question.split(",")[0]?.includes(".ogg") ? (
                <video width="320" height="240" controls>
                  <source src={question.split(",")[0]} type="video/mp4" />
                  <source src={question.split(",")[0]} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="inline-block py-2">{question.split(",")[0]}</p>
              )}
              {question?.split(",")[1] &&
              question.split(",")[1]?.includes(".mp3") ? (
                <AudioIcon src={question.split(",")[1]} />
              ) : // <audio src={question.split(",")[1]} controls/>
              question.split(",")[1]?.includes(".jpg") ||
                question.split(",")[1]?.includes(".png") ? (
                <img
                  className="w-72 my-10 mx-auto shadow-lg rounded-lg"
                  src={question.split(",")[1]}
                  alt=""
                />
              ) : question.split(",")[1]?.includes(".mp4") ||
                question.split(",")[1]?.includes(".mkv") ||
                question.split(",")[1]?.includes(".avi") ||
                question.split(",")[1]?.includes(".ogg") ? (
                <video width="321" height="241" controls>
                  <source src={question.split(",")[1]} type="video/mp4" />
                  <source src={question.split(",")[1]} type="video/ogg" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <p className="inline-block py-2">{question.split(",")[1]}</p>
              )}
            </Typography>
          </Box>
          {question?.split(",")[2] &&
          question.split(",")[2]?.includes(".mp3") ? (
            <AudioIcon src={question.split(",")[2]} />
          ) : // <audio src={question.split(",")[2]} controls/>
          question.split(",")[2]?.includes(".jpg") ||
            question.split(",")[1]?.includes(".png") ? (
            <img
              className="w-64 my-10 mr-auto shadow-lg rounded-lg"
              src={question.split(",")[2]}
              alt=""
            />
          ) : question.split(",")[2]?.includes(".mp4") ||
            question.split(",")[2]?.includes(".mkv") ||
            question.split(",")[2]?.includes(".avi") ||
            question.split(",")[2]?.includes(".ogg") ? (
            <video width="322" height="242" controls>
              <source src={question.split(",")[2]} type="video/mp4" />
              <source src={question.split(",")[2]} type="video/ogg" />
              Your browser does not support the video tag.
            </video>
          ) : (
            <p className="inline-block py-2">{question.split(",")[2]}</p>
          )}
          {code && (
            <p className="text-md text-gray font-bold">
              Standard Code : <span className="text-md">({code})</span>
            </p>
          )}
          <p className="text-md text-gray font-bold">
            Your answer : <span className="text-md">({score} points)</span>
          </p>
          <div className="flex flex-row justify-center gap-10">
            <div
              className={`w-1/2 my-3 p-4 flex flex-row font-bold justify-stretch items-center gap-2 rounded-xl border-b-8 ${
                corAnswer == 1
                  ? "border border-green bg-lightGreen"
                  : "border border-easyGray bg-white"
              }`}
            >
              True
            </div>
            <div
              className={`w-1/2 rounded-xl my-3 p-4 flex flex-row font-bold justify-stretch items-center gap-2 border-b-8  ${
                corAnswer == 0
                  ? "border border-green bg-lightGreen"
                  : "border border-easyGray bg-white"
              }`}
            >
              False
            </div>
          </div>
        </Box>
      )}
      {/* ---------------- Short notes ------------------ */}
      {type === "SHN" && (
        <Box
          sx={{
            mx: "auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
            },
          }}
        >
          <p className="text-[#2962ff] px-6 py-2 flex flex-row items-center gap-1">
            <span className="bg-[#2962ff] text-white w-7 h-7 justify-center items-center rounded-full inline-flex">
              <DriveFileRenameOutlineIcon className="" fontSize="small" />
            </span>{" "}
            <p className="text-bold text-lg">Writing</p>
          </p>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {/* {(question?.split(",")[0] &&
                question.split(",")[0]?.includes(".mp3")) ||
              question.split(",")[0]?.includes(".png") ?
               (
                <img
                  className="inline-block px-3"
                  src={require("@/assets/voice.png")}
                  alt=""
                />
              ) 
              : (
                <p className="py-2">{question.split(",")[0]}</p>
              )} */}

              {question?.split(",")[0] &&
              question.split(",")[0]?.includes(".mp3") ? (
                // <audio src={question.split(",")[0]} controls/>
                <AudioIcon src={question.split(",")[0]} />
              ) : question.split(",")[0]?.includes(".png") ? (
                <img className="w-96" src={question.split(",")[0]} alt="" />
              ) : (
                <p className="inline-block py-2">{question.split(",")[0]}</p>
              )}
              {question?.split(",")[1] &&
              question.split(",")[1]?.includes(".mp3") ? (
                // <audio src={question.split(",")[1]} controls/>
                <AudioIcon src={question.split(",")[1]} />
              ) : question.split(",")[1]?.includes(".png") ? (
                <img className="w-96" src={question.split(",")[1]} alt="" />
              ) : (
                <p className="inline-block py-2">{question.split(",")[1]}</p>
              )}

              {question?.split(",")[2] &&
              question.split(",")[2]?.includes(".mp3") ? (
                // <audio src={question.split(",")[2]} controls/>
                <AudioIcon src={question.split(",")[2]} />
              ) : question.split(",")[2]?.includes(".png") ? (
                <img src={question.split(",")[2]} alt="" />
              ) : (
                <p className="py-2">{question.split(",")[2]}</p>
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: "#F7F9FA",
              my: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "flex-start",
            }}
          >
            <p className="text-md text-gray font-bold">
              Your answer : <span className="text-md">({score} points)</span>
            </p>
            <div
              className={`w-full my-3 p-4 flex flex-row font-bold justify-stretch items-center gap-2 rounded-xl border-b-8 border border-green bg-lightGreen`}
            >
              {answer}
            </div>
          </Box>
        </Box>
      )}
      {/* ---------------- Matching ------------------ */}
      {type === "Matching" && (
        <Box
          sx={{
            mx: "auto",
            width: {
              xs: "100%",
              sm: "100%",
              md: "60%",
            },
          }}
        >
          <p className="text-[#f7ad66] py-2 flex flex-row items-center gap-1">
            <span className="bg-[#f7ad66] text-white w-7 h-7 justify-center items-center rounded-full inline-flex">
              <MenuBookIcon className="" fontSize="small" />
            </span>{" "}
            <p className="text-bold text-lg">Reading</p>
          </p>
          {code && (
            <p className="text-md text-gray font-bold">
              Standard Code : <span className="text-md">({code})</span>
            </p>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                fontWeight: "600",
                fontSize: "18px",
              }}
            >
              {/* {(question?.split(",")[0] &&
                question.split(",")[0]?.includes(".mp3")) ||
              question.split(",")[0]?.includes(".png") ? (
                <img
                  className="inline-block px-3"
                  src={require("@/assets/voice.png")}
                  alt=""
                />
              ) : (
                <p className="py-2">{question.split(",")[0]}</p>
              )} */}

              {question?.split(",")[0] &&
              question.split(",")[0]?.includes(".mp3") ? (
                // <audio src={question.split(",")[0]} controls/>
                <AudioIcon src={question.split(",")[0]} />
              ) : question.split(",")[0]?.includes(".png") ||
                question.split(",")[0]?.includes(".jpg") ? (
                <img className="w-96" src={question.split(",")[0]} alt="" />
              ) : (
                <p className="inline-block py-2">{question.split(",")[0]}</p>
              )}
              {question?.split(",")[1] &&
              question.split(",")[1]?.includes(".mp3") ? (
                // <audio src={question.split(",")[1]} controls/>
                <AudioIcon src={question.split(",")[1]} />
              ) : question.split(",")[1]?.includes(".png") ? (
                <img className="w-96" src={question.split(",")[1]} alt="" />
              ) : (
                <p className="inline-block py-2">{question.split(",")[1]}</p>
              )}

              {question?.split(",")[2] &&
              question.split(",")[2]?.includes(".mp3") ? (
                // <audio src={question.split(",")[2]} controls/>
                <AudioIcon src={question.split(",")[2]} />
              ) : question.split(",")[2]?.includes(".png") ? (
                <img src={question.split(",")[2]} alt="" />
              ) : (
                <p className="py-2">{question.split(",")[2]}</p>
              )}
            </Typography>
          </Box>

          <Box
            sx={{
              bgcolor: "#F7F9FA",
              my: 2,
              p: 2,
              display: "flex",
              flexDirection: "column",
              justifyContent: "stretch",
              alignItems: "flex-start",
            }}
          >
            <p className="text-md text-gray font-bold">
              Your answer : <span className="text-md">({score} points)</span>
            </p>
          </Box>
          <div className="flex flex-col gap-0 mb-4">
            <MatchingFlow answer={answer} corAnswer={corAnswer} />
          </div>
        </Box>
      )}
      <img className="w-72 h-72 scale-x-[-1] mx-20" src={bird} alt="" />
    </Box>
  );
};

export default QuestionPreview;
